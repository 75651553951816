<template>
  <section class="policy-list">
    <div class="margin-bottom">
      <div class="flex flex-justify header-title-back">
        <h2>Car Make</h2>
        <div>
          <v-button
            class="button-theme"
            @click="handlerCreate">
            <font-awesome-icon
              class="withe-color"
              icon="pen"></font-awesome-icon>
            <span>
              Add Make
            </span>
          </v-button>
        </div>
      </div>
    </div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item,index) in tabList"
        :key = index
        :name="item.name"
      >
        <span slot="label">{{item.label}} ({{makeTypeNum(item.name)}})</span>
      </el-tab-pane>
    </el-tabs>
    <div class="show-table-maxscreen">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-class-name="'table-header'"
      >
        <el-table-column
          prop="id"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>ID</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Make</span>
              <v-input
                v-model="name"
                placeholder="Search"
                @change="handlerChange"></v-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Status</span>
              <v-select
                v-model="status"
                :list="makeListStatus"
                @change="handlerChange">
              </v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span>
              <span
                class="span-options"
                :class="{
                  'dark':scope.row.status==2,
                  'green':scope.row.status==1,
                  'red':scope.row.status==0,
                }"
              >{{scope.row.status==1?'Enable':'Disable'}}</span>
            </span>
          </template>
        </el-table-column>

        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Action</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              class="color-primary"
              @click="handeEdit(scope.row)">Edit</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col>
                  <span class="is-bold">Make</span>
                  <v-input
                    v-model="name"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Status</span>
                  <v-select
                    v-model="status"
                    :list="makeListStatus"
                    @change="handlerChange"></v-select>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
      >
        <div
          class="tr">
          <span>ID</span>
          <span>{{item.id}}</span>
        </div>
        <div
          class="tr">
          <span>Make</span>
          <span>{{item.name}}</span>
        </div>

        <div
          class="tr">
          <span>Tel. of Referrer</span>
          <span>
            <span
              class="span-options"
              :class="{
                'dark':item.status==2,
                'green':item.status==1,
                'red':item.status==0,
              }"
            >{{item.status==1?'Enable':'Disable'}}</span>
          </span>
        </div>

        <div
          class="tr">
          <span>Action</span>
          <span
            class="primary"
            @click="handeEdit(item)">Edit</span>
        </div>

      </div>
    </div>

    <make-dialog
      v-model="showDailog"
      :is-edit="isEdit"
      :class-id="activeName"
      :form="makeData"
      @refresh="handlerRefresh"></make-dialog>
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import makeDialog from '@/views/components/dialog/makeDialog'
import {getMakeList} from '@api/setting'
import {mapActions} from 'vuex'
export default {
  name: 'memberReferral',
  components:{
    makeDialog
  },
  mixins: [MixinOptions,MixinFormat],
  props:{
  },
  data(){

    return {
      showDailog:false,
      show:false,
      isEdit:false,
      status:'',
      name:'',
      detailData: [],
      makeData: {},
      detailAllData: [],
      activeName:'1003'
    }
  },
  computed:{
    makeListStatus(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.makeStatusOptions)
    },
    tabList(){
      return[
        {
          name:'1003',
          label:'Motorcycle',
          num:''
        },
        {
          name:'1001',
          label:'Private Car',
          num:''
        },
        {
          name:'1002',
          label:'Commercial Car',
          num:''
        },
      ]
    },
    tableData(){
      let arr =[]
      this.detailData.some((item=>{
        if(item.class_id==Number(this.activeName)){
          arr = item.option
        }
      }))
      return arr
    },
    makeTypeNum(){
      return (class_id)=>{
        let num =0
        this.detailAllData.some((item=>{
          if(item.class_id==Number(class_id)){
            num = item.option.length||0
          }
        }))
        return num||0
      }
    }
  },
  async created(){
    await  this.network().getAllMakeList()
    this.network().getMakeList()
  },
  methods:{
    handleClick(){
      this.network().getMakeList()
    },
    async handlerRefresh(){
      await this.network().getAllMakeList()
      this.network().getMakeList()
    },
    handlerCreate(){
      this.isEdit = false
      this.makeData = null
      this.showDailog = true
    },
    handeEdit(item){
      this.makeData = item
      this.isEdit = true
      this.showDailog = true
    },
    handlerChange(){
      this.network().getMakeList()
    },
    network() {
      return {
        getMakeList: async () => {
          const { data } = await getMakeList({
            class_id:this.activeName,
            name:this.name,
            status:this.status
          })
          this.detailData = data
          // let itemSearch = data&&data.length>0?data[0]:{}
          // this.detailData.filter(item=>{
          //   if(item.class_id==this.activeName){
          //     item.option = itemSearch.option||[]
          //   }
          // })
        },
        getAllMakeList: async () => {
          const { data } = await getMakeList({
          })
          this.detailAllData = data
          // this.detailData = data
        },
        createReferrer: async (params) => {
          const { data } = await createReferrer(params)
          this.handlerChange()
          this.updateGlobalOptions()

        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.margin-bottom{
  margin-bottom: 20px;
}
.header-title-back{
  h2{
    font-size: 36px;
  }
}
.title-tip{
  color: #4A5568;
  font-size: 16px;
  font-weight: 600;
  .number{
    font-size: 16px;
    font-weight: normal;
    margin: 0 25px 0 12px;
  }
}
.header-title-back{
  .button-theme{
    font-size: 16px;
    font-weight: 600;
    color: #fff!important;
    span{
      color: #fff!important;
    }
    .btn-label{
      color: #fff!important;
    }
    .withe-color{
      color: white;
    }
  }
}
.span-options{
  padding: 1px 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  background: #fff;
  border-radius: 4px;
  &.dark{
    color: #718096;
    border: 1px solid #718096;
  }
  //&.primary{
  //  color: $primary;
  //  border: 1px solid $primary;
  //}
  &.green{
    color: #10B981;
    border: 1px solid #10B981;
  }
  &.red{
    color: #FF4343;
    border: 1px solid #FF4343;
  }
  &.color-info{
    border: 1px solid #7FC008;
    color: #7FC008;
  }
  &.color-cancel{
    border: 1px solid #FC8181;
    color: #FC8181;
  }
  &.orange{
    border: 1px solid #ED8936!important;
    color: #ED8936!important;
  }
}

</style>
